/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@import '~swiper/scss';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper';
@import '~swiper/scss/effect-fade';

@import '~animate.css/animate.min.css';

.headerHome{
    --background: #18094e;
    --color: white;
}

.imgLogo{
    position: absolute;
    top: 15px;
    left: 15px;
    width: 6%;
    
}
.contenedorLogin{
    z-index: 5000;
    .colLogin{
        z-index: 5000;
        ion-button{
            z-index: 5000;
        }
    }
}
.rowLogin{
    margin-top: -35px !important;
    z-index: 0;
}
.toolbarHome{
    --background: #18094e;
    --color: white;
    height: 90px !important;
    --min-height: 90px !important;
    toolbar-container{
        height: 110px !important;
    }
}
.lblLogin{
    font-size:0.8em;
    text-transform: capitalize;
}
.lblLoginmini{
    font-size:0.7em;
    text-transform: capitalize;
}

.inputCampo{
    background: white;
    color: black !important;
    border-radius: 10px;
    --padding-bottom: 5px;
    --padding-end: 10px;
    --padding-start: 10px;
    --padding-top: 10px;
}
.coluser{
    text-align: left;
    padding: 0 3px;
}
.registroPopover::part(content){
    --width: 350px !important;
    --offset-x: 30px;
    --offset-y: 30px;
    overflow: hidden;
}
.error_message{
    font-size: 0.7em;
    font-weight:200;
    color: #fa1909;
    font-family: Arial, Helvetica, sans-serif;
}
.animated {
    visibility: visible !important;
}
.hide-on-init {
    visibility: hidden;
}